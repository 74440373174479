/** @jsx jsx */
import { Styled, Box, Flex, jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query {
      tuula: file(relativePath: { eq: "tuula.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 480) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Ajankohtaista" />
      <Styled.h1 sx={{ textAlign: "center" }}>Yhteystiedot</Styled.h1>
      <Flex
        sx={{
          maxWidth: 1024,
          mx: "auto",
          my: [4, 5],
          flexWrap: ["wrap", "nowrap"],
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: ["auto", 320],
            mr: [0, 4, 5],
            flex: "none",
            order: [1, 0],
          }}
        >
          <Img fluid={data.tuula.childImageSharp.fluid} alt="Tuula Tikkanen" />
        </Box>
        <Box
          sx={{
            fontFamily: "display",
            textAlign: ["center", "left"],
            fontSize: 3,
            order: [0, 1],
          }}
        >
          <Styled.p>
            <strong>Tilaukset:</strong>{" "}
            <Styled.a href="mailto:santevin@santevin.fi">
              santevin@santevin.fi
            </Styled.a>
          </Styled.p>
          <Styled.p>Toimitus sopimuksen mukaan.</Styled.p>
          <Styled.p>
            Santevin
            <br />
            Hatanpäänvaltatie 2<br />
            33100 Tampere
            <br />
            <Styled.a href="mailto:santevin@santevin.fi">
              santevin@santevin.fi
            </Styled.a>
            <br />
            Puh. +358 400 858 213 / Tuula Tikkanen
          </Styled.p>
        </Box>
      </Flex>
      <Box
        sx={{
          position: "relative",
          height: 300,
          width: "100%",
          mx: "auto",
          maxWidth: 1024,
        }}
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1903.9289959320843!2d23.764025716302097!3d61.49751238246936!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x468ed8ac869fc2ff%3A0x1f295f1242c99fa0!2sChampagne%20bar%20Sant%C3%A9!5e0!3m2!1sfi!2sfi!4v1576000038729!5m2!1sfi!2sfi"
          frameBorder="0"
          allowFullScreen=""
          sx={{ position: "absolute", width: "100%", height: "100%" }}
        ></iframe>
      </Box>
    </Layout>
  )
}

export default ContactPage
